import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { ClusterAnalysis } from "./types"
import api from "../api"

export interface GetHealthAnalysisParameters {
  accountId: number
  clusterId: number
  reqType: string
  progressCallback: (loading: boolean, err?: any) => any
}

export interface FetchError {
  error: string
  offline: boolean
  status: number
}

export interface ClustersState {
  loadingAnalysis: boolean
  analysis: ClusterAnalysis | null
  accountId: number | null
  clusterId: number | null
  errorState: string
}

const initialState: ClustersState = {
  loadingAnalysis: false,
  analysis: null,
  accountId: null,
  clusterId: null,
  errorState: ""
}

export const getHealthAsync = createAsyncThunk<
  ClusterAnalysis,
  GetHealthAnalysisParameters,
  { rejectValue: FetchError }
>(
  "clusters/getHealthAsync",
  async (args: GetHealthAnalysisParameters, { rejectWithValue }) => {
    const { accountId, clusterId, reqType, progressCallback } = args
    progressCallback(true)
    return api
      .analyzeHealth({
        accountId: accountId,
        clusterId: clusterId,
        reqType: reqType
      })
      .then((analyzeReport: ClusterAnalysis) => {
        progressCallback(false)
        return analyzeReport
      })
      .catch((err: any) => {
        progressCallback(false, err)
        return rejectWithValue(err)
      })
  }
)

export const clusterHomeSlice = createSlice({
  name: "clusters",
  initialState,
  reducers: {
    resetClusterHome: (state: ClustersState) => initialState
  },
  extraReducers: builder => {
    builder
      .addCase(getHealthAsync.pending, (state, action) => {
        state.loadingAnalysis = true
        state.accountId = action.meta.arg.accountId
        state.clusterId = action.meta.arg.clusterId
        state.errorState = ""
      })
      .addCase(getHealthAsync.rejected, (state, action) => {
        state.loadingAnalysis = false
        if (action.payload) {
          let payload: FetchError = action.payload
          state.errorState = payload.error
        }
      })
      .addCase(getHealthAsync.fulfilled, (state, action) => {
        state.loadingAnalysis = false
        state.analysis = action.payload
        state.accountId = action.meta.arg.accountId
        state.clusterId = action.meta.arg.clusterId
      })
  }
})

// Action creators are generated for each case reducer function

export const { resetClusterHome } = clusterHomeSlice.actions
export default clusterHomeSlice.reducer
