import {
  PLAN_ENTERPRISE,
  PLAN_ENTERPRISE_LITE,
  PLAN_ENTERPRISE_PLUS,
  PLAN_STANDARD,
  PLAN_TRIAL
} from "../subscription/SubscriptionConstants"
import {
  ISSUE_STATUS_ANSWERED,
  ISSUE_STATUS_ARCHIVED,
  ISSUE_STATUS_OPEN,
  ISSUE_STATUS_WAITING_FOR_RESPONSE,
  NORMAL_ISSUE,
  PANIC_ISSUE
} from "../constants"
import { createNewSortInstance, sort } from "fast-sort"
import { groupBy } from "../health/HealthUtils"

const sortUnAnsweredIssues = (conversations: any) => {
  const planOrder = {
    [PLAN_ENTERPRISE]: 1,
    [PLAN_ENTERPRISE_PLUS]: 2,
    [PLAN_ENTERPRISE_LITE]: 3,
    [PLAN_STANDARD]: 4,
    [PLAN_TRIAL]: 7,
    "": 8
  }

  const issueTypeOrder = {
    [PANIC_ISSUE]: 1,
    [NORMAL_ISSUE]: 2
  }
  const ansStatusOrder = {
    [ISSUE_STATUS_WAITING_FOR_RESPONSE]: 1,
    [ISSUE_STATUS_OPEN]: 2,
    [ISSUE_STATUS_ANSWERED]: 3,
    [ISSUE_STATUS_ARCHIVED]: 4
  }

  const sortedIssueList = sort(conversations).by([
    {
      inPlaceSorting: true,
      // @ts-ignore
      asc: conv => issueTypeOrder[conv.issueType] || 0
    }, //New panic issues on Top

    // @ts-ignore
    {
      asc: (conv: any) => {
        if (
          conv.accountType === PLAN_ENTERPRISE_PLUS &&
          conv?.account?.is_trial
        ) {
          return 5
        }
        if (
          conv.accountType === PLAN_ENTERPRISE_LITE &&
          conv?.account?.is_trial
        ) {
          return 6
        }

        // @ts-ignore
        return planOrder[conv.accountType] || 0
      }, // @ts-ignore
      inPlaceSort: true
    }, //  issues from old to new by plan
    // @ts-ignore
    { asc: conv => ansStatusOrder[conv.issueStatus] || 0, inPlaceSort: true }, // un answered
    {
      inPlaceSorting: true,
      // @ts-ignore
      asc: (conv: any) => {
        const {
          issue: {
            issue_answer_state: { timestamp = null, user_type = null } = {}
          } = {},
          lastUpdatedAt,
          issueStatus
        } = conv

        let slaStartTime = lastUpdatedAt
        if (
          ISSUE_STATUS_WAITING_FOR_RESPONSE === issueStatus &&
          user_type === "customer"
        ) {
          slaStartTime = timestamp
        }

        new Date(slaStartTime)
      } // by oldest unanswered.
    }
  ])
  return sortedIssueList
}

const dateSorter = createNewSortInstance({
  inPlaceSorting: true,
  // @ts-ignore
  comparer: (a, b) => new Date(b.lastUpdatedAt) - new Date(a.lastUpdatedAt)
})

export const sortConversations = (conversations = []) => {
  if (!conversations.length) {
    return []
  }
  const issueGroups = groupBy(conversations, (c: any) => c.issueStatus)
  const waitingGroup = issueGroups.get(ISSUE_STATUS_WAITING_FOR_RESPONSE) || []
  const openGroup = issueGroups.get(ISSUE_STATUS_OPEN) || []

  const sortedWaitingGroup = sortUnAnsweredIssues(waitingGroup)
  const sortedOpenGroup = sortUnAnsweredIssues(openGroup)
  let openIssuesGroup = [...sortedWaitingGroup, ...sortedOpenGroup]

  //just sort the answered group by newest to oldest
  const answeredGroup = dateSorter(
    issueGroups.get(ISSUE_STATUS_ANSWERED) || []
  ).asc()
  //just sort the archived group by newest to oldest
  const archivedGroup = dateSorter(
    issueGroups.get(ISSUE_STATUS_ARCHIVED) || []
  ).asc()

  const issueList = openIssuesGroup.concat(answeredGroup).concat(archivedGroup)

  return issueList
}
