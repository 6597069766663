import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import api from "../api"
import { RootState } from "../configure-store"

type ClusterListStateType = {
  clusterList: any[]
  loading: boolean
  error: null | any
}

const initialState: ClusterListStateType = {
  clusterList: [],
  loading: false,
  error: null
}

export const getClusterList = createAsyncThunk<any, number>(
  "clusters/getList",
  async (accountId, thunkAPI) => {
    try {
      return api.getRegisteredClusters(accountId)
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

const clusterListSlice = createSlice({
  name: "clusters",
  initialState,
  reducers: {
    resetClusterList: state => initialState
  },
  extraReducers: builder => {
    builder
      .addCase(getClusterList.pending, state => {
        state.error = null
        state.loading = true
      })
      .addCase(getClusterList.fulfilled, (state, action) => {
        state.loading = false
        state.clusterList = action.payload
      })
      .addCase(getClusterList.rejected, (state, action) => {
        state.error = action.payload
        state.loading = false
      })
  }
})

export const clusterListSelectorFn = (state: RootState) =>
  state.registeredClusters.clusterList || []
export const { resetClusterList } = clusterListSlice.actions

export default clusterListSlice.reducer
