import thunkMiddleware from "redux-thunk"
import appReducer from "./reducers"
import { AnyAction, configureStore } from "@reduxjs/toolkit"
import clusterReducer from "./clusters/clusterHomeSlice"
import clusterListReducer from "./clusters/clusterListSlice"
import categoriesSlice from "./categories/categoriesSlice"
import { useDispatch } from "react-redux"

const middlewares = [thunkMiddleware]

// TODO: Remove this when the appReducer is transitioned to slices
const combinedReducer = (state: any, action: AnyAction) => {
  let appState = appReducer(state, action)

  const getStateHolder = (inKey: string) => {
    return state && state[inKey] ? state[inKey] : state
  }

  let regClusState = getStateHolder("registeredClusters")
  let clusterHomeState = getStateHolder("clusterHome")
  let categoriesState = getStateHolder("categories")

  let regClusterState = clusterListReducer(regClusState, action)
  let categoriesReducer = categoriesSlice(categoriesState, action)
  let clusterHomeReducer = clusterReducer(clusterHomeState, action)

  return {
    ...appState,
    registeredClusters: regClusterState,
    clusterHome: clusterHomeReducer,
    categories: categoriesReducer
  }
}

export const store = configureStore({
  reducer: combinedReducer,
  middleware: middlewares
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
