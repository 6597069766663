import api from "./api"
import { gotoIssue } from "./route-helpers"
import {
  ALERT_DANGER,
  ALERT_INFO,
  ERROR_FETCH_USERS,
  ERROR_FETCH_ISSUE,
  ERROR_CREATE_ISSUE,
  ERROR_UPDATE_ISSUE,
  ERROR_UPLOAD_FILE,
  ERROR_OFFLINE,
  THUMBNAIL_STATUS_LOADED,
  THUMBNAIL_STATUS_LOADING,
  THUMBNAIL_STATUS_NOTFOUND,
  ERROR_FETCH_ISSUES,
  ISSUE_STATUS_ANSWERED,
  ISSUE_STATUS_ARCHIVED,
  ERROR_LIST_NOTICES,
  ACCOUNT_TYPE_CUSTOM
} from "./constants"
import { downloadFromUrl, getIssueStatus, getIssueType } from "./utils"
import { endOfDay, isBefore } from "date-fns"
import { getCustomSubscriptionEndAlert } from "./subscription/Utils"
import { sortConversations } from "./issue/IssueListUtils"
import {
  getHealthReportAlert,
  getTncUpdateAlert
} from "./subscription/AlertBannerUtils"

export const RESET_APP = "app/RESET"

export const SHOW_ISSUE_LOADER = "loader/SHOW_ISSUE"
export const HIDE_ISSUE_LOADER = "loader/HIDE_ISSUE"

export const SET_ALERT = "alert/SET"
export const CLEAR_ALERT = "alert/CLEAR"

export const SET_USER_INFO = "userinfo/SET"

export const SHOW_TEXT_UPLOAD_MODAL = "messages/SHOW_TEXT_UPLOAD_MODAL"
export const HIDE_TEXT_UPLOAD_MODAL = "messages/HIDE_TEXT_UPLOAD_MODAL"

export const BEGIN_UPLOAD = "uploads/BEGIN"
export const CANCEL_UPLOAD = "uploads/CANCEL"
export const COMPLETE_UPLOAD = "uploads/COMPLETE"
export const SET_UPLOAD_PERCENTAGE = "uploads/SET_PERCENTAGE"
export const SHOW_ABORT_UPLOAD = "loader/SHOW_ABORT"
export const HIDE_ABORT_UPLOAD = "loader/HIDE_ABORT"

export const SHOW_SEARCH_MENU = "search/SHOW"
export const HIDE_SEARCH_MENU = "search/HIDE"
export const SET_SEARCH_MESSAGE_INDEX = "search/SET_INDEX"
export const RESET_SEARCH_MESSAGE_INDEX = "search/RESET_INDEX"
export const SET_SEARCH_PARAMS = "search/SET_PARAMS"
export const RESET_SEARCH_PARAMS = "search/RESET_PARAMS"

export const ADD_TYPING_MEMBER = "members/ADD_TYPING_MEMBER"
export const REMOVE_TYPING_MEMBER = "members/REMOVE_TYPING_MEMBER"
export const RESET_TYPING_MEMBERS = "members/RESET_TYPING_MEMBERS"

export const SET_ENGINEERS = "engineers/SET"
export const RESET_ENGINEERS = "engineers/RESET"

export const SET_CONVERSATIONS = "conversations/SET"
export const ADD_CONVERSATION = "conversations/ADD"
export const UPDATE_CONVERSATION = "conversations/UPDATE"
export const RESET_CONVERSATIONS = "conversations/RESET"
export const SET_CONVERSATION_LAST_UPDATED_AT =
  "conversations/SET_LAST_UPDATED_AT"
export const SET_UNREAD_COUNT = "conversations/SET_UNREAD_COUNT"

export const SET_ACCOUNTS = "accounts/SET"

export const SET_USERS = "users/SET"
export const SET_USER_THUMBNAIL = "users/SET_THUMBNAIL"
export const RESET_USER_THUMBNAILS = "users/RESET_THUMBNAILS"
export const SET_ONLINE_USERS = "users/SET_ONLINE"

export const SHOW_MOBILE_SIDEBAR = "common/SHOW_MOBILE_SIDEBAR"
export const HIDE_MOBILE_SIDEBAR = "common/HIDE_MOBILE_SIDEBAR"

export const SHOW_MOBILE_SEARCH = "common/SHOW_MOBILE_SEARCH"
export const HIDE_MOBILE_SEARCH = "common/HIDE_MOBILE_SEARCH"

export const SHOW_VIRTUAL_TOUR = "tour/SHOW_VIRTUAL_TOUR"
export const HIDE_VIRTUAL_TOUR = "tour/HIDE_VIRTUAL_TOUR"

export const SET_SELECTED_ACCOUNT = "accounts/SET_SELECTED"
export const RESET_SELECTED_ACCOUNT = "accounts/RESET_SELECTED"

export const SET_ISSUE_FILTER = "issue/SET_FILTER"

export const PLAY_NOTIFICATION_SOUND = "notification/PLAY_SOUND"
export const STOP_NOTIFICATION_SOUND = "notification/STOP_SOUND"
export const SET_NOTIFICATION_STATUS = "notification/SET_STATUS"

export const SET_TWILIO_STATE = "twilio/SET_STATE"

export const SET_ISSUE = "issue/SET"
export const CLEAR_ISSUE = "issue/CLEAR"
export const UPDATE_ISSUE = "issue/UPDATE"
export const SET_ACCOUNTS_SEARCH_TEXT = "accounts/SET_ACCOUNTS_SEARCH_TEXT"
export const SET_ENGINEERS_SEARCH_TEXT = "users/SET_ENGINEERS_SEARCH_TEXT"
export const SET_USERS_SEARCH_TEXT = "users/SET_USERS_SEARCH_TEXT"
export const SET_ACCOUNT_DETAILS_SEARCH_TEXT =
  "accounts/SET_ACCOUNT_DETAILS_SEARCH_TEXT"
export const SET_NON_MEMBER_USER_SEARCH_TEXT =
  "accounts/SET_NON_MEMBER_USER_SEARCH_TEXT"

export const SET_SUBSCRIPTION_INFO = "user/SET_SUBSCRIPTION_INFO"
export const SET_APP_ENV = "app/SET_APP_ENV"

export const SET_REGISTERED_CLUSTERS = "health/SET_REGISTERED_CLUSTERS"
export const SET_CLUSTER_ANALYSIS_RESULT = "health/SET_CLUSTER_ANALYSIS_RESULT"

export const SET_ALERTS_SUMMARY = "alerts/SET_ALERTS_SUMMARY"

export const SET_FEEDBACK_ACCOUNT_FILTER =
  "feedback/SET_FEEDBACK_ACCOUNT_FILTER"

export const SET_LICENSES_ACCOUNT_FILTER =
  "licenses/SET_LICENSES_ACCOUNT_FILTER"

export const SET_FILE_LIST_SEARCH_TEXT = "files/SET_FILE_LIST_SEARCH_TEXT"

export const SHOW_ASSIGN_MODAL = "modal/SHOW_ASSIGN_MODAL"
export const HIDE_ASSIGN_MODAL = "modal/HIDE_ASSIGN_MODAL"

export const SET_TODO_SUMMARY = "todo/SET_TODO_SUMMARY"

export const OPEN_ISSUE_TAB = "issue/newTab"
export const CLOSE_ISSUE_TAB = "issue/closeTab"
export const LOAD_ISSUE_INFO = "issue/LOAD_ISSUE_INFO"
export const RESTORE_OPEN_TABS = "issue/RESTORE_OPEN_TABS"
export const UPDATE_OPEN_ISSUE_TAB = "issue/UPDATE_OPEN_ISSUE_TAB"
export const RESET_ACTIVE_TABS = "issue/RESET_ACTIVE_TABS"

export const resetApp = () => ({
  type: RESET_APP
})

export const setAccounts = (accounts, isLoading) => ({
  type: SET_ACCOUNTS,
  accounts: accounts,
  isLoading: isLoading
})

export const setAlert = alert => ({
  type: SET_ALERT,
  alert: alert
})

export const displayOfflineAlert = () => {
  return dispatch => {
    dispatch(
      setAlert({
        type: ALERT_INFO,
        message: "No internet connection",
        errorCode: ERROR_OFFLINE
      })
    )
  }
}

export const clearAlert = () => ({
  type: CLEAR_ALERT
})

export const clearAlertByCode = errorCode => {
  return (dispatch, getState) => {
    if (getState().alert && getState().alert.errorCode === errorCode) {
      dispatch(clearAlert())
    }
  }
}

export const setUserInfo = userInfo => ({
  type: SET_USER_INFO,
  userInfo: userInfo
})

export const updateUserInfo = userInfo => {
  return dispatch => {
    dispatch(setUserInfo(userInfo))
  }
}

export const refreshUserInfo = () => {
  return dispatch => {
    api
      .GetUserInfo()
      .then(userInfo => {
        dispatch(setUserInfo(userInfo))
      })
      .catch(err => {
        dispatch(
          setAlert({
            type: ALERT_DANGER,
            message: "Error while fetching user information : " + err.error,
            errorCode: ERROR_FETCH_USERS
          })
        )
      })
  }
}

export const refreshAccounts = () => {
  return (dispatch, getState) => {
    // If the accounts are being loaded then skip
    if (getState().accounts.isLoading) {
      return
    }
    dispatch(setAccounts(getState().accounts.list, true))
    dispatch(fetchAccounts())
  }
}

export const fetchAccounts = () => {
  return dispatch => {
    api
      .getAccounts()
      .then(accounts => {
        dispatch(setAccounts(accounts, false))
      })
      .catch(error => {
        console.error("Error getting Accounts:", error)
      })
  }
}

export const createIssue = (subject, accountId, message) => {
  return (dispatch, getState) => {
    dispatch(showIssueLoader())
    api
      .createIssue(subject, accountId, message)
      .then(res => {
        gotoIssue(res.issueNumber)
      })
      .catch(err => {
        dispatch(
          setAlert({
            type: ALERT_DANGER,
            message: "Error while creating issue : " + err.error,
            errorCode: ERROR_CREATE_ISSUE
          })
        )
      })
  }
}

export const sendFileMessage = (channel, file, filename, mimeType) => {
  return (dispatch, getState) => {
    const { account_id, issue_number } = getState().issue
    const fileId = new Date().getTime()

    const fName = filename || file.name
    const mType = mimeType || file.type

    const updatePercentage = percentage => {
      if (percentage) {
        if (percentage === 100) {
          dispatch(completeUpload(fileId))
          dispatch(hideAbortUploadModal())
        } else {
          dispatch(setUploadPercentage(percentage, fileId))
        }
      }
    }
    const cancel = () => {
      const itemToCancel = getState().upload.find(
        item => item.fileId === fileId && item.isCancelled === true
      )
      const cancelResult = itemToCancel ? true : false
      if (cancelResult) {
        completeUpload(fileId)
      }

      return cancelResult
    }

    dispatch(beginUpload(fileId, fName))
    api
      .uploadAttachment(
        account_id,
        issue_number,
        fName,
        file,
        updatePercentage,
        cancel
      )
      .then(res => {
        channel.sendMessage("", {
          type: "attachment",
          filename: fName,
          mimeType: mType,
          uploadId: res.uploadId,
          size: file.size
        })
        dispatch(completeUpload(fileId))
      })
      .catch(err => {
        dispatch(
          setAlert({
            type: ALERT_DANGER,
            message: "Error while uploading file : " + err.error,
            errorCode: ERROR_UPLOAD_FILE
          })
        )
        dispatch(completeUpload(fileId))
      })
  }
}

export const downloadAttachment = (
  accountId,
  issueNumber,
  uploadId,
  filename
) => {
  return (dispatch, getState) => {
    const url = api.getAttachmentUrl(
      accountId,
      issueNumber,
      uploadId,
      filename,
      false
    )
    const fullUrl = window.location.origin + url
    downloadFromUrl(fullUrl, filename)
  }
}

export const showTextUploadModal = () => ({
  type: SHOW_TEXT_UPLOAD_MODAL
})

export const hideTextUploadModal = () => ({
  type: HIDE_TEXT_UPLOAD_MODAL
})

export const beginUpload = (fileId, fileName, uploadFrom) => ({
  type: BEGIN_UPLOAD,
  fileId: fileId,
  fileName: fileName,
  uploadFrom: uploadFrom
})

export const completeUpload = fileId => ({
  type: COMPLETE_UPLOAD,
  fileId: fileId
})

export const cancelUpload = fileId => ({
  type: CANCEL_UPLOAD,
  fileId: fileId
})

export const setUploadPercentage = (percentage, fileId) => {
  return {
    type: SET_UPLOAD_PERCENTAGE,
    percentage: percentage,
    fileId: fileId
  }
}

export const showAbortUploadModal = () => ({
  type: SHOW_ABORT_UPLOAD
})

export const hideAbortUploadModal = () => ({
  type: HIDE_ABORT_UPLOAD
})

export const showSearchMenu = () => ({
  type: SHOW_SEARCH_MENU
})

export const hideSearchMenu = () => ({
  type: HIDE_SEARCH_MENU
})

export const addTypingMember = member => ({
  type: ADD_TYPING_MEMBER,
  member: member
})

export const removeTypingMember = member => ({
  type: REMOVE_TYPING_MEMBER,
  member: member
})

export const resetTypingMembers = () => ({
  type: RESET_TYPING_MEMBERS
})

export const setEngineers = engineers => ({
  type: SET_ENGINEERS,
  engineers: engineers
})

export const resetEngineers = () => ({
  type: RESET_ENGINEERS
})

export const setOnlineUsers = userIds => ({
  type: SET_ONLINE_USERS,
  onlineUserIds: userIds
})

let channelUpdatedHandler
let messageAddedHandler
let userUpdatedHandler

export const addTwilioListeners = tc => {
  return (dispatch, getState) => {
    userUpdatedHandler = ({ user, updateReasons }) => {
      // Fetch online users list and update locally
      tc.getSubscribedUsers()
        .then(users => {
          const onlineUserIds = users
            .filter(u => u.isOnline)
            .map(u => u.identity)
          dispatch(setOnlineUsers(onlineUserIds))
        })
        .catch(err => {
          console.log("Error from tc.getSubscribedUsers:", err)
        })
    }

    channelUpdatedHandler = ({ conversation: channel, updateReasons }) => {
      const currentState = getState()
      const { conversations: existingConversations } = currentState
      const conversation = existingConversations.find(
        c => c.issue.twilio_channel_id === channel.sid
      )

      if (updateReasons.indexOf("lastMessage") >= 0) {
        if (conversation) {
          const lastUpdatedAt = channel.lastMessage
            ? channel.lastMessage.dateCreated
            : channel.dateUpdated

          dispatch(setConversationLastUpdatedAt(conversation, lastUpdatedAt))
        }
      } else if (updateReasons.indexOf("lastReadMessageIndex") >= 0) {
        if (conversation) {
          // Sometimes getUnconsumedMessagesCount returns non-zero value even
          // when the last message is consumed. In that case, we set the
          // unread count to 0 without calling getUnconsumedMessagesCount
          if (channel.lastMessage.index === channel.lastReadMessageIndex) {
            dispatch(setConversationUnreadCount(conversation, 0))
          } else {
            channel
              .getUnreadMessagesCount()
              .then(count => {
                dispatch(setConversationUnreadCount(conversation, count))
              })
              .catch(error => {
                console.error("Error getting unconsumed messages count:", error)
              })
          }
        }
      }
    }

    messageAddedHandler = message => {
      const channel = message.conversation
      const conversation = getState().conversations.find(
        c => c.issue.twilio_channel_id === channel.sid
      )
      const currentUser = getState().userInfo
      if (conversation) {
        // we don't need to increase the unread count for user's own msgs
        if (currentUser.userId && currentUser.userId !== message.author) {
          const unreadCount = (conversation.unreadMessageCount || 0) + 1
          dispatch(setConversationUnreadCount(conversation, unreadCount))
        }
      }
    }
    tc.on("conversationUpdated", channelUpdatedHandler)
    tc.on("messageAdded", messageAddedHandler)

    api
      .getEngineers()
      .then(engineers => {
        dispatch(setEngineers(engineers))

        // Subscribe to updates
        // ud.subscribe() returns the subscribed user
        const list = engineers.map(e => tc.getUser(e.userId))
        Promise.allSettled(list)
          .then(res => {
            // Set the initial online users
            const onlineUserIds = res
              .filter(r => r.status === "fulfilled")
              .map(r => r.value)
              .filter(u => u.isOnline)
              .map(u => u.identity)
            dispatch(setOnlineUsers(onlineUserIds))

            // Register for userUpdated event
            tc.on("userUpdated", userUpdatedHandler)
          })
          .catch(error => {
            console.error("Error getting user descriptors:", error)
          })
      })
      .catch(error => {
        console.error("Error getting engineers list:", error)
      })
  }
}

export const removeTwilioListeners = tc => {
  return dispatch => {
    channelUpdatedHandler &&
      tc.removeListener("conversationUpdated", channelUpdatedHandler)
    messageAddedHandler &&
      tc.removeListener("messageAdded", messageAddedHandler)
    userUpdatedHandler && tc.removeListener("userUpdated", userUpdatedHandler)
  }
}

export const setConversations = (conversations, autoRefresh) => ({
  type: SET_CONVERSATIONS,
  conversations: conversations,
  autoRefresh: autoRefresh
})

export const resetConversations = () => ({
  type: RESET_CONVERSATIONS
})

export const addConversation = conversation => ({
  type: ADD_CONVERSATION,
  conversation: conversation
})

export const updateConversation = conversation => ({
  type: UPDATE_CONVERSATION,
  conversation: conversation
})

export const setConversationLastUpdatedAt = (conversation, timestamp) => ({
  type: SET_CONVERSATION_LAST_UPDATED_AT,
  conversation: conversation,
  lastUpdatedAt: timestamp
})

export const setConversationUnreadCount = (conversation, count) => ({
  type: SET_UNREAD_COUNT,
  conversation: conversation,
  count: count
})

const getIssuesList = (page, accountId, issueFilter) => {
  return api
    .listIssues(page, accountId, issueFilter)
    .then(issueListPageData => issueListPageData)
}

const getIssueFetchStrategyPromise = (
  autoRefresh,
  pageNumber,
  accountId,
  issueFilter
) => {
  if (!autoRefresh) {
    return getIssuesList(pageNumber, accountId, issueFilter)
  } else {
    const pageNumbersToRefresh = Array.from(Array(pageNumber + 1).keys()) //0+1
    const pagesToLoad = pageNumbersToRefresh.map(pageNum => {
      return getIssuesList(pageNum, accountId, issueFilter)
    })

    return Promise.all(pagesToLoad).then(allLoadedPagesData => {
      const allPageRows = allLoadedPagesData.flat(1)
      return allPageRows
    })
  }
}

export const fetchIssues = (autoRefresh, pageNumber, accountId) => {
  return (dispatch, getState) => {
    const {
      issueFilter,
      categories: { categories: expertiseCategories },
      userInfo
    } = getState()
    const existingConversations = getState().conversations || []
    if (!autoRefresh && existingConversations.length === 0) {
      dispatch(showIssueLoader())
    }

    /* for expCategoryId find level and  pass , exclude showAll as it is ui only filter option but default in server */
    const { expCategoryId, showAll, ...restIssueFilters } = issueFilter
    let issueFilterConf = { ...restIssueFilters }
    const { expertiseCategoryId } = userInfo
    const expertiseFilter = expCategoryId || !Object.keys(issueFilter).length
    const isDefaultFilter = !showAll && expertiseFilter
    if (isDefaultFilter) {
      const { level } = expertiseCategories[expertiseCategoryId] || {}
      if (level) {
        issueFilterConf = {
          ...issueFilterConf,
          expCatLevel: level
        }
      }
    }

    const issueFetchStrategyPromise = getIssueFetchStrategyPromise(
      autoRefresh,
      pageNumber,
      accountId,
      issueFilterConf
    )

    issueFetchStrategyPromise
      .then(pagedData => {
        let issues = []
        if (pagedData) {
          issues = [...issues, ...pagedData]
        }

        let list = issues.map(issue => ({
          channelId: issue.twilio_channel_id,
          issue: issue,
          issueStatus: getIssueStatus(issue),
          issueType: getIssueType(issue),
          lastUpdatedAt: issue.issue_answer_state.timestamp
        }))

        dispatch(setConversations(list, autoRefresh))

        if (!autoRefresh) {
          dispatch(hideIssueLoader())
        } else {
          dispatch(clearAlertByCode(ERROR_OFFLINE))
        }
      })
      .catch(err => {
        if (!autoRefresh) {
          dispatch(
            setAlert({
              type: ALERT_DANGER,
              message: "Error while fetching the issues : " + err.error,
              errorCode: ERROR_FETCH_ISSUES
            })
          )
        } else if (err.offline) {
          dispatch(displayOfflineAlert())
        }
      })
  }
}

export const setUsers = users => ({
  type: SET_USERS,
  users: users
})

export const fetchUsers = (accountId, autoRefresh) => {
  return dispatch => {
    api
      .getUsers(accountId)
      .then(users => {
        dispatch(setUsers(users))
        if (autoRefresh) {
          dispatch(clearAlertByCode(ERROR_OFFLINE))
        }
      })
      .catch(err => {
        if (!autoRefresh) {
          dispatch(
            setAlert({
              type: ALERT_DANGER,
              message: "Error while fetching users : " + err.error,
              errorCode: ERROR_FETCH_USERS
            })
          )
        } else if (err.offline) {
          dispatch(displayOfflineAlert())
        }
      })
  }
}

export const refreshUserThumbnails = () => {
  return (dispatch, getState) => {
    Object.keys(getState().userThumbnails).forEach(userId => {
      dispatch(fetchUserThumbnail(userId))
    })
  }
}

export const fetchUserThumbnail = userId => {
  return (dispatch, getState) => {
    const thumbnail = getState().userThumbnails[userId]

    // skip for system messages where userId is undefined
    if (!userId) {
      return
    }

    // We skip if the thumbnail is being loaded
    if (thumbnail && thumbnail.status === THUMBNAIL_STATUS_LOADING) {
      return
    }

    // We set to LOADING only when there is no thumbnail
    if (!thumbnail) {
      dispatch(setUserThumbnail(userId, THUMBNAIL_STATUS_LOADING, undefined))
    }
    api
      .getUserThumbnail(userId)
      .then(data => {
        // Revoke previous thumbnail url
        if (thumbnail && thumbnail.url) {
          URL.revokeObjectURL(thumbnail.url)
        }
        if (data.size > 0) {
          const url = URL.createObjectURL(data)
          dispatch(setUserThumbnail(userId, THUMBNAIL_STATUS_LOADED, url))
        } else {
          dispatch(
            setUserThumbnail(userId, THUMBNAIL_STATUS_NOTFOUND, undefined)
          )
        }
      })
      .catch(() => {
        dispatch(setUserThumbnail(userId, THUMBNAIL_STATUS_NOTFOUND, undefined))
      })
  }
}

export const setUserThumbnail = (userId, status, url) => ({
  type: SET_USER_THUMBNAIL,
  userId: userId,
  status: status,
  url: url
})

export const resetUserThumbnails = () => ({
  type: RESET_USER_THUMBNAILS
})

export const clearUserThumbnails = () => {
  return (dispatch, getState) => {
    const thumbnails = getState().userThumbnails
    Object.keys(thumbnails).forEach(userId => {
      const thumbnail = thumbnails[userId]
      if (thumbnail && thumbnail.url) {
        URL.revokeObjectURL(thumbnail.url)
      }
    })
    dispatch(resetUserThumbnails())
  }
}

export const setSearchParams = (query, issueNumber, accountId) => ({
  type: SET_SEARCH_PARAMS,
  query: query,
  issueNumber: issueNumber,
  accountId: accountId
})

export const resetSearchParams = () => ({
  type: RESET_SEARCH_PARAMS
})

export const showIssueLoader = () => ({
  type: SHOW_ISSUE_LOADER
})

export const hideIssueLoader = () => ({
  type: HIDE_ISSUE_LOADER
})

export const setSelectedAccount = accountId => ({
  type: SET_SELECTED_ACCOUNT,
  accountId: accountId
})

export const setIssueFilter = filterValue => ({
  type: SET_ISSUE_FILTER,
  filterValue: filterValue
})

export const resetSelectedAccount = () => ({
  type: RESET_SELECTED_ACCOUNT
})

export const showSidebar = () => ({
  type: SHOW_MOBILE_SIDEBAR
})

export const hideSidebar = () => ({
  type: HIDE_MOBILE_SIDEBAR
})

export const showSearch = () => ({
  type: SHOW_MOBILE_SEARCH
})

export const hideSearch = () => ({
  type: HIDE_MOBILE_SEARCH
})

export const showTour = () => ({
  type: SHOW_VIRTUAL_TOUR
})

export const hideTour = () => ({
  type: HIDE_VIRTUAL_TOUR
})

export const playNotificationSound = () => ({
  type: PLAY_NOTIFICATION_SOUND
})

export const stopNotificationSound = () => ({
  type: STOP_NOTIFICATION_SOUND
})

export const setTwilioState = twilioState => ({
  type: SET_TWILIO_STATE,
  twilioState: twilioState
})

export const setIssue = issue => ({
  type: SET_ISSUE,
  issue: issue
})

export const openIssueTab = (issueNumber, issue) => {
  return {
    type: OPEN_ISSUE_TAB,
    issueNumber: issueNumber,
    issue: issue
  }
}

export const updateOpenIssueTab = (issueNumber, issue) => {
  return {
    type: UPDATE_OPEN_ISSUE_TAB,
    issueNumber: issueNumber,
    issue: issue
  }
}

export const closeIssueTab = issueNumber => {
  return {
    type: CLOSE_ISSUE_TAB,
    issueNumber: issueNumber
  }
}

export const resetActiveTabs = issueNumber => {
  return {
    type: RESET_ACTIVE_TABS
  }
}
export const loadIssueInfo = (issueNumber, issue) => {
  return {
    type: LOAD_ISSUE_INFO,
    issueNumber: issueNumber,
    issue: issue
  }
}

export const restoreOpenIssueTabs = tabsToRestore => {
  return {
    type: RESTORE_OPEN_TABS,
    tabsToRestore: tabsToRestore
  }
}
export const clearIssue = () => ({
  type: CLEAR_ISSUE
})

export const updateIssue = issue => ({
  type: UPDATE_ISSUE,
  issue: issue
})

export const showAssignModal = issueNumber => ({
  type: SHOW_ASSIGN_MODAL,
  assignModalOpenFor: issueNumber
})

export const hideAssignModal = () => ({
  type: HIDE_ASSIGN_MODAL
})

export const setIssueAnswered = (issue, answered) => {
  return dispatch => {
    dispatch(
      updateIssue({
        ...issue,
        issue_answer_state: {
          ...issue.issue_answer_state,
          state: answered
        }
      })
    )
  }
}

export const setIssueAnsweredFromList = (issue, eventInfo) => {
  const {
    user_type: userType,
    is_answered: answered,
    timestamp: lastUpdatedAt
  } = eventInfo
  return (dispatch, getState) => {
    let updatedIssue
    if (userType === undefined) {
      updatedIssue = {
        ...issue,
        issue_answer_state: {
          ...issue.issue_answer_state,
          state: answered,
          // archived state will always be false when answered state is altered
          is_archived: false
        }
      }
    } else {
      updatedIssue = {
        ...issue,
        issue_answer_state: {
          ...issue.issue_answer_state,
          state: answered,
          user_type: userType,
          // archived state will always be false when answered state is altered
          is_archived: false
        }
      }
    }
    const conversations = getState().conversations
    const conversationIndex = conversations.findIndex(
      c => c.issue.issue_number === issue.issue_number
    )
    const currConversation = conversations[conversationIndex]
    const newConversation = {
      ...currConversation,
      issue: updatedIssue,
      issueStatus: getIssueStatus(updatedIssue),
      lastUpdatedAt: lastUpdatedAt
    }
    let newConversations = [...conversations]
    // remove current conversation from list. if the answered or archived
    // issues are not loaded on the window yet, we only remove the current
    // conversation from the list as the conversation will be loaded when the
    // user scrolls down to display the answered/archived issues
    newConversations.splice(conversationIndex, 1)
    // calculate the indexes of the first answered issue and first archived
    // issue to determine where to place the conversation that we have just
    // removed
    const firstAnsweredIndex = newConversations.findIndex(
      c => c.issueStatus === ISSUE_STATUS_ANSWERED
    )
    const firstArchivedIndex = newConversations.findIndex(
      c => c.issueStatus === ISSUE_STATUS_ARCHIVED
    )
    // find the index of the last open panic issue
    const lastPanicIndex = newConversations
      .map(c => {
        if (c.issueStatus === ISSUE_STATUS_ARCHIVED) {
          return "Archived"
        } else if (c.issueStatus === ISSUE_STATUS_ANSWERED) {
          return "Answered"
        } else {
          return c.issueType
        }
      })
      .lastIndexOf("Panic")
    // if conversation is a Panic issue and the issue is marked as unanswered
    // move it right after the last Panic conversation listed
    if (!answered && currConversation.issueType === "Panic") {
      // move conversation after the last occurrence of panic issue
      newConversations.splice(lastPanicIndex + 1, 0, newConversation)
    } else if (firstAnsweredIndex !== -1) {
      // move conversation before the first occurrence of the answered issue
      newConversations.splice(firstAnsweredIndex, 0, newConversation)
    } else if (firstArchivedIndex !== -1) {
      // move conversation before the first occurrence of the archived issue
      newConversations.splice(firstArchivedIndex, 0, newConversation)
    } else {
      // if none of these conditions are satisfied, put the conversation back
      // to the place it was removed from
      newConversations.splice(conversationIndex, 0, newConversation)
    }
    dispatch(setConversations(sortConversations(newConversations), true))
  }
}

export const setIssueLastMsgIndex = (payLoad = {}) => {
  return (dispatch, getState) => {
    const { date: msgTimestamp, issue_number, index } = payLoad
    const conversation = getState().conversations.find(
      c => c.issue.issue_number === issue_number
    )
    // If the issue is not on the list, return here
    if (!conversation) {
      return
    }

    const isSetNewTimestamp = ISSUE_STATUS_ARCHIVED === conversation.issueStatus

    const newConversation = {
      ...conversation,
      //when customer sends a message on an archived issue, the backend timestamp is not modified.
      // update the timestamp here to indicate re-open for SLA.
      ...(payLoad.user_type === "customer" &&
        isSetNewTimestamp && {
          lastUpdatedAt: msgTimestamp
        }),
      issue: {
        ...conversation.issue,
        ...(isSetNewTimestamp && {
          issue_answer_state: {
            ...conversation.issue.issue_answer_state,
            timestamp: msgTimestamp //if arhived make is as answered timestamp so that issue list reads it as ISSUE_STATUS_WAITING_FOR_RESPONSE start timestamp
          }
        }),
        last_message_index: index
      }
    }
    dispatch(updateConversation(newConversation))
  }
}

export const refreshIssue = (issue, autoRefresh) => {
  return dispatch => {
    api
      .getIssue(issue.issue_number)
      .then(issue => {
        dispatch(updateIssue(issue))
        if (autoRefresh) {
          dispatch(clearAlertByCode(ERROR_OFFLINE))
        }
      })
      .catch(err => {
        if (!autoRefresh) {
          dispatch(
            setAlert({
              type: ALERT_DANGER,
              message: "Error while fetching issue : " + err.error,
              errorCode: ERROR_FETCH_ISSUE
            })
          )
        } else if (err.offline) {
          dispatch(displayOfflineAlert())
        }
      })
  }
}

export const updateIssueTitle = (issue, title) => {
  return (dispatch, getState) => {
    const issueNumber = issue.issue_number
    api
      .updateIssueTitle(issueNumber, title)
      .then(res => {
        dispatch(
          updateIssue({
            ...issue,
            title: title
          })
        )
      })
      .catch(err => {
        dispatch(
          setAlert({
            type: ALERT_DANGER,
            message: "Error while updating issue title : " + err.error,
            errorCode: ERROR_UPDATE_ISSUE
          })
        )
      })
  }
}

export const setNotificationsStatus = notificationsStatus => ({
  type: SET_NOTIFICATION_STATUS,
  notificationsStatus: notificationsStatus
})

export const checkAndShowSubscriptionAlerts = () => {
  return (dispatch, getState) => {
    const { userInfo: { accountId } = {}, subscriptionInfo = {} } = getState()
    if (accountId) {
      api
        .getNotices(accountId)
        .then((noticeList = []) => {
          if (noticeList.length) {
            const [firstNotice = {}] = noticeList
            const { message = "", type = "", details = {} } = firstNotice
            let alertConfig = {
              type: ALERT_DANGER,
              message: message,
              alertType: type
            }
            if (type === "CLUSTER_DUES") {
              alertConfig = getHealthReportAlert(
                ALERT_DANGER,
                message,
                details.clusters
              )
            } else if (type === "TNC_UPDATE") {
              alertConfig = getTncUpdateAlert(
                ALERT_INFO,
                details.alert_id,
                message,
                () => {
                  dispatch(clearAlert())
                }
              )
            }
            dispatch(setAlert(alertConfig))
          }
        })
        .finally(() => {
          const { cancel_at, account_type } = subscriptionInfo
          if (account_type === ACCOUNT_TYPE_CUSTOM && cancel_at) {
            const endBy = endOfDay(new Date(cancel_at))
            const isDatePassed = isBefore(endBy, endOfDay(new Date()))

            if (isDatePassed) {
              const alertConfig = getCustomSubscriptionEndAlert()
              dispatch(setAlert(alertConfig))
            }
          }
        })
        .catch(err => {
          dispatch(
            setAlert({
              type: ALERT_DANGER,
              message: "Error while listing notices: " + err.error,
              errorCode: ERROR_LIST_NOTICES
            })
          )
        })
    }
  }
}

export const setSubscriptionInfo = subscriptionInfo => ({
  type: SET_SUBSCRIPTION_INFO,
  subscriptionInfo: subscriptionInfo
})

export const updateSubscriptionInfo = subscriptionInfo => {
  return dispatch => {
    dispatch(setSubscriptionInfo(subscriptionInfo))
  }
}

export const setAccountsSearchText = searchText => {
  return {
    type: SET_ACCOUNTS_SEARCH_TEXT,
    accountsSearchText: searchText
  }
}

export const setNonMemberUsersSearchText = nonMemberUsersSearchText => {
  return {
    type: SET_NON_MEMBER_USER_SEARCH_TEXT,
    nonMemberUsersSearchText: nonMemberUsersSearchText
  }
}

export const setAccountDetailsSearchText = searchText => {
  return {
    type: SET_ACCOUNT_DETAILS_SEARCH_TEXT,
    accountDetailsSearchText: searchText
  }
}

export const setEngineersSearchText = searchText => {
  return {
    type: SET_ENGINEERS_SEARCH_TEXT,
    engineersSearchText: searchText
  }
}

export const setUsersSearchText = searchText => {
  return {
    type: SET_USERS_SEARCH_TEXT,
    usersSearchText: searchText
  }
}

export const setRegisteredClusters = registeredClusters => {
  return {
    type: SET_REGISTERED_CLUSTERS,
    registeredClusters: registeredClusters
  }
}

export const setClusterAnalysisResult = (clusterId, analysisResult) => {
  return {
    type: SET_CLUSTER_ANALYSIS_RESULT,
    clusterId: clusterId,
    analysisResult: analysisResult
  }
}

export const setAppEnv = appEnv => {
  return {
    type: SET_APP_ENV,
    appEnv: appEnv
  }
}

export const setAlertsSummary = alertsSummary => {
  return {
    type: SET_ALERTS_SUMMARY,
    alertsSummary: alertsSummary
  }
}

export const setFeedbackAccountFilter = accountId => {
  return {
    type: SET_FEEDBACK_ACCOUNT_FILTER,
    accountId: accountId
  }
}

export const setLicensesAccountFilter = accountId => {
  return {
    type: SET_LICENSES_ACCOUNT_FILTER,
    accountId: accountId
  }
}

export const setFileListSearchText = searchTerm => {
  return {
    type: SET_FILE_LIST_SEARCH_TEXT,
    fileListSearchText: searchTerm
  }
}

export const setTodoSummary = todoSummary => {
  return {
    type: SET_TODO_SUMMARY,
    todoSummary: todoSummary
  }
}

export const fetchTodoSummary = () => {
  return dispatch => {
    api
      .getTodoSummary()
      .then(summary => {
        dispatch(setTodoSummary(summary))
      })
      .catch(err => {
        console.log("Error in fetching todos summary: ", err.error)
      })
  }
}
